import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Page from '../components/page';
import Section from '../components/section';
import Projects from '../components/projects';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import { ArrowButtonLink } from '../components/arrow-button';
import SubscribeFormIntro from '../components/subscribe-form-intro';
import SubscribeForm from '../components/subscribe-form';

const pageTheme = {
};

const IndexPage = ({ location }) => {
  return (
    <Page location={location}>
      <ThemeProvider theme={pageTheme}>
        <main>
          <Helmet>
            {/* Use the default description */}
            {/* <meta name="description" content=""/> */}
          </Helmet>
          <Projects/>
          <Section as="aside">
            <LayoutRow>
              <MainColumn>
                <h1>Need branding or web work?</h1>
                <ArrowButtonLink className="accent" to="/contact">enquire<br/>here</ArrowButtonLink>
              </MainColumn>
            </LayoutRow>
          </Section>
          <Section as="aside">
            <LayoutRow>
              <MainColumn>
                <SubscribeFormIntro/>
                <SubscribeForm/>
              </MainColumn>
            </LayoutRow>
          </Section>
        </main>
      </ThemeProvider>
    </Page>
  );
};

export default IndexPage;
