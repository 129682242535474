import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const boxShadowHeight = props => `${1 - props.theme.linkLinePixels}px`;

// For use with Markdown component
const styledLinkMixin = css`
    text-decoration: none;
    color: ${props => props.theme.linkColor};
    font-weight: ${props => props.theme.linkFontWeight};
    border-bottom: 1px solid ${props => props.theme.linkLineColor};
    box-shadow: inset 0 ${props => boxShadowHeight(props)} 0 0 ${props => props.theme.linkLineColor};
    outline: none;

    &:hover {
        background: ${props => props.theme.linkLineColor};
    }

    &:focus {
        outline: ${props => props.theme.buttonFocusOutline};
        outline-offset: 2px;
    }
`;

const altStyledLinkMixin = css`
    text-decoration: none;
    color: ${props => props.theme.altLinkColor};
    font-weight: ${props => props.theme.altLinkFontWeight};
    outline: none;

    &:hover {
        // background: ${props => props.theme.altLinkLineColor};
        border-bottom: 1px solid ${props => props.theme.altLinkLineColor};
        box-shadow: inset 0 ${props => boxShadowHeight(props)} 0 0 ${props => props.theme.altLinkLineColor};
    }

    &:focus {
        outline: ${props => props.theme.buttonFocusOutline};
        outline-offset: 2px;
    }
`;

const StyledLink = styled(Link)`
    ${styledLinkMixin}
`;

const StyledA = styled.a`
    ${styledLinkMixin}
`;

const AltStyledLink = styled(Link)`
    ${altStyledLinkMixin}
`;

const AltStyledA = styled.a`
    ${altStyledLinkMixin}
`;

export { styledLinkMixin, StyledA, StyledLink as default, AltStyledLink, AltStyledA };