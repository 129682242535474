import React from 'react';

const Video = ({
  src,
  poster,
  autoplayShortcut,
  autoplay,
  loop,
  muted,
  playsInline,
  ...props}) => {
  return (
    <video
      src={src}
      poster={poster}
      autoPlay={autoplay === undefined ? autoplayShortcut : autoplay}
      muted={muted === undefined ? autoplayShortcut : muted}
      playsInline={playsInline === undefined ? autoplayShortcut : playsInline}
      loop={loop}
      {...props}
    >
      {props.children}
    </video>
  )  
};

export default Video;
