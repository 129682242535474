import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { FullWidthBackground } from '../utils/layout-row';
import ie from '../utils/ie';
import Video from './video';

const objectPositionDefault = '65% 25%';

const MediaContainer = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    > * {
        margin: 0;
        height: 100%;
        width: 100%;
        object-fit: ${props => props.fit || 'cover'};
        object-position: ${props => props.position || objectPositionDefault};

        // for IE polyfill (object-fit-images)
        font-family: 'object-fit: cover !important; object-position: ${props =>
            props.position || objectPositionDefault} !important;'
    }

    > video {
        // Hide video on IE, which doesn't support object-fit
        ${ie`display: none;`}
    }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
    height: 100%;

    img {
        margin: 0;
        object-fit: ${props => props.fit || 'cover'} !important;
        object-position: ${props => props.position || objectPositionDefault} !important;

        // for IE polyfill (object-fit-images)
        font-family: 'object-fit: cover !important; object-position: ${props =>
            props.position || objectPositionDefault} !important;'
    }
`;

const StyledBanner = styled(FullWidthBackground)`
    background-color: ${props =>
        props.isDark ? props.theme.darkBackgroundColor : 'unset'};
`;

const BannerImage = ({ banner }) => {
    if (banner.image.extension === 'svg') {
        return (
            <MediaContainer position={banner.position}>
                <img
                src={banner.image.publicURL}
                alt={banner.alt} />
            </MediaContainer>
        );
    }
    
    return (
        <StyledGatsbyImage
        fluid={banner.image.childImageSharp.fluid}
        position={banner.position}
        alt={banner.alt}
        />
    );
};

const BannerVideo = ({ banner }) => {
    if (!banner.video) {
        return null;
    }

    return (
        <MediaContainer position={banner.position}>
            <Video
            src={banner.video.publicURL}
            // poster={banner.poster.publicURL}
            autoplayShortcut={true} />
        </MediaContainer>
    )
};

const Banner = ({ banner }) => {
    return (
        <StyledBanner isDark={banner.isDark}>
            <BannerImage banner={banner} />
            <BannerVideo banner={banner} />
        </StyledBanner>
    )
};

export const fragment = graphql`
    fragment Banner on MarkdownRemarkFrontmatterBanner {
        image {
            childImageSharp {
                fluid(maxWidth: 2600, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
            extension
            publicURL
        }
        video {
            publicURL
        }
        #poster {
        #    publicURL
        #}
        alt
        isDark
        position
    }
`;

export default Banner;
