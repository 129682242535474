import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Arrow from '../content/general/arrow.svg';

const ArrowButtonContainer = styled.button.attrs({className: 'small'})`
  &,
  &:visited {
    text-decoration: none;
    background: none;
    display: flex;
    width: 100%;
    padding: 0;
    text-align: left;
    position: relative;
    color: ${props => props.theme.buttonColor};
    border: none;
    z-index: 10;
    max-width: 400px !important;

    .arrow {
      position: absolute;
      min-height: 60px;
      height: 100%;
      width: 100%;
      filter: ${props => props.theme.buttonShadowFilter};
      transition: all 0.1s;
    }

    .button-label {
      position: relative;
      margin: 10px 21px;
    }

    .arrow-line { fill: ${props => props.theme.buttonLineColor}; }
    .arrow-background { fill: ${props => props.theme.buttonBackgroundColor}; }
  }

  &.accent:not(:disabled) {
    color: ${props => props.theme.buttonAccentColor};
    .arrow-line { fill: ${props => props.theme.buttonAccentLineColor}; }
    .arrow-background { fill: ${props => props.theme.buttonAccentBackgroundColor}; }
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &.selected {
    color: ${props => props.theme.buttonActiveColor};
    .arrow-line { fill: ${props => props.theme.buttonActiveLineColor}; }
    .arrow-background { fill: ${props => props.theme.buttonActiveBackgroundColor}; }
  }

  &:active:not(:disabled) {
    filter: none;
    z-index: auto;
  }

  &:focus {
    outline: ${props => props.theme.buttonFocusOutline};
    outline-offset: 2px;
  }

  &:disabled {
    .arrow { filter: none; }
    z-index: auto;
    opacity: 0.5;
  }
`;

// Avoid vertically centered button text
const ButtonLabel = styled.span`
  display: block;
  height: inherit;
  position: relative;
  padding: 10px 24px;
`;

const ArrowButton = ({ children, ...props }) => (
  <ArrowButtonContainer {...props}>
    <Arrow />
    <ButtonLabel>{children}</ButtonLabel>
  </ArrowButtonContainer>
);

const ArrowButtonLink = (props) => <ArrowButton as={Link} {...props}/>;
const ArrowButtonA = (props) => <ArrowButton as="a" {...props}/>;

export { ArrowButton, ArrowButtonLink, ArrowButtonA };
