import React from 'react';
import styled from 'styled-components';
import { styledLinkMixin } from './styled-link';

const StyledMarkdown = styled.div`
    a:not(.gatsby-resp-image-link) {
        ${styledLinkMixin}
    }

    h2, h3, h4, h5, h6 {
        margin-top: 40px;
    }
`;

const Markdown = ({ className, html }) => {
    return <StyledMarkdown
        className={className}
        dangerouslySetInnerHTML={{ __html: html }} />
};

export default Markdown;
